export const expandInfo = () => {
  const d = document;

  const hasExpandInfo = d.querySelector('.icon');

  if (!hasExpandInfo) return;

  const expand = d.getElementsByClassName('icon');
  const expandConsigli = d.getElementsByClassName('iconConsigli');

  const infoExpandBox = d.getElementsByClassName(
    'territorio__info-content-container'
  );
  const consigliExpandBox = d.getElementsByClassName('info__content-container');
  const consigliInfoBox = d.getElementsByClassName('territorio__info-promo');

  for (let i = 0; i < expand.length; i++) {
    expand[i].addEventListener('click', () => {
      if (expand[i].classList.contains('active')) {
        expand[i].src = 'src/assets/plus.svg';
        expand[i].classList.remove('active');
        infoExpandBox[i].classList.remove(
          'territorio__info-content-container--active'
        );
      } else {
        expand[i].src = 'src/assets/minus.svg';
        expand[i].classList.add('active');
        infoExpandBox[i].classList.add(
          'territorio__info-content-container--active'
        );
      }
    });
  }
  for (let i = 0; i < expandConsigli.length; i++) {
    expandConsigli[i].addEventListener('click', () => {
      if (expandConsigli[i].classList.contains('active')) {
        expandConsigli[i].src = 'src/assets/plus.svg';
        expandConsigli[i].classList.remove('active');
        consigliExpandBox[i].classList.remove(
          'info__content-container--active'
        );
        document.getElementsByClassName('info__wrapper-box')[i].style.padding =
          '';
      } else {
        expandConsigli[i].src = 'src/assets/minus.svg';
        expandConsigli[i].classList.add('active');
        consigliExpandBox[i].classList.add('info__content-container--active');
        document.getElementsByClassName('info__wrapper-box')[i].style.padding =
          '0';
      }
    });
  }

  for (let i = 0; i < expand.length; i++) {
    expand[i].addEventListener('click', () => {
      if (
        consigliInfoBox[i].classList.contains(
          'territorio__info-content-container--active-info',
          'territorio__info-promo'
        )
      ) {
        consigliInfoBox[i].classList.remove(
          'territorio__info-content-container--active-info'
        );
      } else {
        consigliInfoBox[i].classList.add(
          'territorio__info-content-container--active-info'
        );
      }
    });
  }
};
