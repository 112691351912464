import '../stylesheets/style.scss';
import { cruscotto } from './cruscotto';
import { homeGlide } from './homeGlide';
import { roomGlide } from './roomGlide';
import { expandInfo } from './info-expand';
import { calendar } from './flatpickr';
import { menu } from './menu';

cruscotto();
homeGlide();
roomGlide();
expandInfo();
calendar();
menu();
