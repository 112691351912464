import Glide from '@glidejs/glide';

export const roomGlide = () => {
  const d = document;

  const hasRoomGlide = d.querySelector('.comfortRoom');

  if (!hasRoomGlide) {
    return;
  } else {
    new Glide('.comfortRoom', {
      type: 'carousel',
      gap: 0,
      breakpoints: {
        1024: {},
        1500: {
          perView: 1.4,
          slideWidth: 435,
        },
        6000: {
          perView: 1.4,
          slideWidth: 500,
        },
      },
    }).mount();

    new Glide('.superiorRoom', {
      type: 'carousel',
      gap: 0,
      breakpoints: {
        1024: {},
        1500: {
          perView: 1.4,
          slideWidth: 435,
        },
        6000: {
          perView: 1.4,
          slideWidth: 500,
        },
      },
    }).mount();
  }

  new Glide('.relaxRoom', {
    type: 'carousel',
    gap: 0,
    breakpoints: {
      1024: {},
      1500: {
        perView: 1.4,
        slideWidth: 435,
      },
      6000: {
        perView: 1.4,
        slideWidth: 500,
      },
    },
  }).mount();

  new Glide('.suiteRoom', {
    type: 'carousel',
    gap: 0,
    breakpoints: {
      1024: {},
      1500: {
        perView: 1.4,
        slideWidth: 435,
      },
      6000: {
        perView: 1.4,
        slideWidth: 500,
      },
    },
  }).mount();

  new Glide('.planaSlide', {
    type: 'carousel',
    gap: 0,
    breakpoints: {
      1024: {},
      1500: {
        perView: 1.4,
        slideWidth: 435,
      },
      6000: {
        perView: 1.4,
        slideWidth: 500,
      },
    },
  }).mount();
};
