import Glide from '@glidejs/glide';

export const homeGlide = () => {
  const d = document;

  const hasHomeGlide = d.querySelector('.slider-home');

  if (!hasHomeGlide) {
    return;
  } else {
    new Glide('.slider-home', {
      type: 'carousel',
      gap: 0,
      perView: 1.3,
      breakpoints: {
        1024: {},
        1500: {
          perView: 3.3,
          slideWidth: 435,
        },
        6000: {
          perView: 3,
          slideWidth: 500,
        },
      },
    }).mount();
  }
};
