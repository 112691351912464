import flatpickr from 'flatpickr';
import { Italian } from 'flatpickr/dist/l10n/it.js';
import { select } from 'nunjucks/src/filters';

export const calendar = () => {
  const d = document;

  const hasBoxBooking = d.querySelector('.cruscotto');

  if (!hasBoxBooking) return;

  const dataArrivo = d.getElementById('js-calendar-2');
  const dayUseTime = d.getElementById('dayuse-time');
  const dayUseTime2 = d.getElementById('dayuse-time2');
  const rangeDate = d.getElementById('js-from-to-date');
  let arrival = '';
  let departure = '';
  let checkInString = '';
  let checkOutString = '';

  flatpickr('#flatpickr', {
    locale: Italian,
    mode: 'range',
    minDate: 'today',
    dateFormat: 'd-m-Y',

    onReady: (selected, dateStr, instance) => {
      instance.calendarContainer.id = 'calendar-longStay';
      document
        .getElementById('calendar-long-stay')
        .appendChild(instance.calendarContainer);
      let btn = document.createElement('button');
      btn.innerHTML = 'OK';
      instance.calendarContainer.childNodes[1].appendChild(btn);
      btn.id = 'button';
      btn.classList.add('dayuse__button');
      btn.classList.add('dayuse__button--cruscotto');
      btn.style.dispay = 'block';
    },
    onOpen: (selected, dateStr, instance) => {
      document
        .getElementById('calendar-long-stay')
        .classList.add('dayuse__calendar-long-stay');
    },

    onChange: (selected, dateStr, instance) => {
      rangeDate.innerHTML = 'Dal ' + dateStr;
    },
    onClose: (selected, dateStr, instance) => {
      document
        .getElementById('calendar-long-stay')
        .classList.remove('dayuse__calendar-long-stay');
      updateDate(selected, dateStr, instance);
    },
  });

  flatpickr('#dayuse-arrive', {
    onReady: (selected, dateStr, instance) => {
      instance.calendarContainer.classList.add('calendar-dayuse');

      let btn = document.createElement('p');
      btn.innerHTML = 'OK';
      instance.calendarContainer.childNodes[1].appendChild(btn);
      btn.id = 'button2';
      btn.classList.add('dayuse__button');
      btn.classList.add('dayuse__button--cruscotto');
      btn.style.dispay = 'block';
      instance.calendarContainer.id = 'calendar-dayUse';
      document
        .getElementById('calendar-day-use')
        .appendChild(instance.calendarContainer);
    },

    onOpen: (selected, dateStr, instance) => {
      instance.calendarContainer.childNodes[0].classList.add(
        'calendar-dayuse__month'
      );
      instance.calendarContainer.childNodes[1].classList.add(
        'calendar-dayuse__innerContainer'
      );
      document
        .getElementById('calendar-day-use')
        .classList.add('dayuse--calendar-day-use');
    },
    locale: Italian,
    altFormat: 'F j, Y',
    dateFormat: 'Y-m-d',
    disableMobile: 'true',
    onClose: (selected, dateStr, instance) => {
      document
        .getElementById('calendar-day-use')
        .classList.remove('dayuse--calendar-day-use');
    },
  });

  flatpickr('#time-arrive', {
    onReady: (selected, dateStr, instance) => {
      instance.calendarContainer.classList.add('arrival');
    },
    onOpen: (selected, dateStr, instance) => {
      instance.calendarContainer.classList.add('time-arrive');
    },
    locale: Italian,
    enableTime: true,
    noCalendar: true,
    dateFormat: 'H:i',
    time_24hr: true,
    disableMobile: 'true',
    static: true,
    minuteIncrement: 10,

    onChange: (selected, dateStr, instance) => {
      dayUseTime.placeholder = dateStr;
      document.getElementById('text-to-hour').value = dateStr;
      document.getElementById('dayuse-time').value = dateStr;
      document
        .getElementById('text-to-hour')
        .classList.remove('cruscotto__panel-text--bold');

      document.getElementById('text-to-hour').style.width = '46px';
      document.getElementById('text-to-hour').style.fontFamily = 'regular';
    },
    onValueUpdate: (selected, dateStr, instance) => {
      dayUseTime.placeholder = dateStr;
      document.getElementById('text-to-hour').value = dateStr;
      document.getElementById('dayuse-time').value = dateStr;
      document
        .getElementById('text-to-hour')
        .classList.remove('cruscotto__panel-text--bold');
    },
  });
  flatpickr('#time-arrive2', {
    onReady: (selected, dateStr, instance) => {
      instance.calendarContainer.classList.add('departure');
    },
    onOpen: (selected, dateStr, instance) => {
      instance.calendarContainer.classList.add('time-arrive2');
    },
    locale: Italian,
    enableTime: true,
    noCalendar: true,
    dateFormat: 'H:i',
    time_24hr: true,
    disableMobile: 'true',
    static: true,
    minuteIncrement: 10,

    onChange: (selected, dateStr, instance) => {
      dayUseTime2.placeholder = dateStr;
      document.getElementById('dayuse-time2').value = dateStr;
      document.getElementById('text-to-hour-departure').value = ' - ' + dateStr;
      document.getElementById('text-to-hour-departure').style.fontFamily =
        'regular';
    },
    onClose: (selected, dateStr, instance) => {
      console.dir(instance);
    },
  });

  document.getElementById('button').addEventListener('click', function () {
    document.getElementById('calendar-longStay').classList.remove('open');
    document
      .getElementById('calendar-long-stay')
      .classList.remove('dayuse__calendar-long-stay');
    document.getElementById('flatpickr').classList.remove('active');
  });

  document.getElementById('button2').addEventListener('click', function () {
    document.getElementById('calendar-dayUse').classList.remove('open');
    document
      .getElementById('calendar-day-use')
      .classList.remove('dayuse--calendar-day-use');

    document.getElementById('dayuse-arrive').classList.remove('active');
  });

  const checkInCheckOut = document.getElementById('console');
  const addGuest = d.getElementById('add-guest');
  const cancelGuest = d.getElementById('cancel-guest');
  const guestTotal = d.getElementsByClassName('cruscotto__guest-count');

  let counter = 1;

  document.querySelector('.cruscotto__guest-count').innerHTML = `
  ${counter}
  `;
  document.querySelector('.cruscotto__guest-count').setAttribute('id', counter);

  addGuest.addEventListener('click', function () {
    updateGuest(counter + 1);

    if ((counter = 2)) {
      updateGuest(2);
    }
  });
  cancelGuest.addEventListener('click', function () {
    updateGuest(counter - 1);
    if (counter <= 1) {
      updateGuest(1);
    }
  });

  checkInCheckOut.addEventListener('click', function () {
    checkInString = arrival.join('-');
    checkOutString = departure.join('-');
    const ericsoft = `https://booking.ericsoft.com/BookingEngine/Book?idh=6B9EB2DC26D6E642&lang=it&cur=EUR&`;
    const bookingUrl = `${ericsoft}arrival=${checkInString.toString()}&departure=${checkOutString.toString()}&pax=${
      guestTotal[0].id
    }_0&acmp=RT0`;
    window.open(bookingUrl);
  });

  const updateDate = (selected, dateStr, instance) => {
    const minus = selected;
    arrival = [
      selected[0].getFullYear(),
      minus[0].getMonth() + 1 < 10
        ? '0' + (selected[0].getMonth() + 1)
        : selected[0].getMonth() + 1,
      minus[0].getDate() < 10
        ? '0' + selected[0].getDate()
        : selected[0].getDate(),
    ];
    departure = [
      selected[1].getFullYear(),
      minus[1].getMonth() + 1 < 10
        ? '0' + (selected[1].getMonth() + 1)
        : selected[1].getMonth() + 1,
      minus[1].getDate() < 10
        ? '0' + selected[1].getDate()
        : selected[1].getDate(),
    ];
  };

  const updateGuest = (counterGuest) => {
    counter = counterGuest;

    document.querySelector('.cruscotto__guest-count').id = counter;
    document.querySelector('.cruscotto__guest-count').innerHTML = `
      ${counter}`;
  };
};
