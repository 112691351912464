export const cruscotto = () => {
  const d = document;

  const hasBoxBooking = d.querySelector('.cruscotto');

  if (!hasBoxBooking) return;

  const longStay = d.getElementById('js-longStay');
  const dayUse = d.getElementById('js-dayUse');
  const longStayBox = d.getElementById('js-longStay-box');
  const dayUseBox = d.getElementById('js-dayUse-box');

  dayUse.addEventListener('click', function () {
    if (dayUse.classList.contains('cruscotto__panel--dayUse')) {
      longStay.classList.add('cruscotto__panel--longStay');
      dayUse.classList.remove('cruscotto__panel--dayUse');
      longStayBox.classList.remove('cruscotto__panel-settings--active');
      dayUseBox.classList.add('cruscotto__panel-settings--active');
    }
  });

  longStay.addEventListener('click', function () {
    if (longStay.classList.contains('cruscotto__panel--longStay')) {
      longStay.classList.remove('cruscotto__panel--longStay');
      dayUse.classList.add('cruscotto__panel--dayUse');
      longStayBox.classList.add('cruscotto__panel-settings--active');
      dayUseBox.classList.remove('cruscotto__panel-settings--active');
    }
  });

  const hamburgerBox = d.getElementById('js-dayuse-hamburger-panel');
  const hamburger = d.getElementById('dayuse-hamburger');
  const closeHamburger = d.getElementById('dayuse__button');

  const hamburgerHour = d.getElementById('js-dayuse-hamburger-hour');
  const hamburgerHourBox = d.getElementById('dayuse-hour-hamburger');
  const closeHourHamburger = d.getElementById('dayuse-hour__button');

  hamburgerBox.addEventListener('click', function () {
    hamburger.classList.add('dayuse--active');
    hamburger.style.display = 'block';
    closeHamburger.style.display = 'block';
    hamburgerBox.style.display = 'none';
    document
      .getElementById('js-info-camera')
      .classList.add('dayuse__info-day-use');
  });

  closeHamburger.addEventListener('click', function () {
    hamburger.classList.remove('dayuse--active');
    hamburger.style.display = 'none';
    closeHamburger.style.display = 'none';
    hamburgerBox.style.display = 'flex';
    document
      .getElementById('js-info-camera')
      .classList.remove('dayuse__info-day-use');
    if (document.getElementById('Camera Comfort').checked) {
      document.getElementById('room-selected').innerHTML = 'Camera Comfort';
    }
    if (document.getElementById('Camera Superior').checked) {
      document.getElementById('room-selected').innerHTML = 'Camera Superior';
    }
    if (document.getElementById('Junior Suite').checked) {
      document.getElementById('room-selected').innerHTML = 'Junior Suite';
    }
  });

  hamburgerHour.addEventListener('click', function () {
    hamburgerHourBox.classList.add('dayuse--active-input');
    hamburgerHourBox.style.display = 'block';
    closeHourHamburger.style.display = 'block';
    hamburgerHour.style.display = 'none';
    document
      .getElementById('day-use-hour')
      .classList.add('dayuse__hour-day-use');
    document.getElementById('dayuse__sendform-container').remove();
    document
      .getElementById('js-dayUse-box')
      .classList.remove('cruscotto__panel-settings--active-center');
    document
      .getElementById('js-info-camera')
      .classList.remove('cruscotto__panel-box--active-width');
    document
      .getElementById('calendar-day-use')
      .classList.remove('cruscotto__panel-box--active-width');
    document
      .getElementById('day-use-hour')
      .classList.remove('cruscotto__panel-box--active-width');
  });

  closeHourHamburger.addEventListener('click', function () {
    hamburgerHourBox.classList.remove('dayuse--active-input');
    hamburgerHourBox.style.display = 'none';
    closeHourHamburger.style.display = 'none';
    hamburgerHour.style.display = 'flex';
    document
      .getElementById('day-use-hour')
      .classList.remove('dayuse__hour-day-use');

    let sendFormContainer = document.createElement('button');
    sendFormContainer.id = 'dayuse__sendform-container';
    sendFormContainer.classList.add(
      'dayuse__sendform-container',
      'cruscotto__panel-box'
    );
    let sendFormImg = document.createElement('img');
    sendFormImg.src = 'src/assets/panel-arrow.svg';
    sendFormImg.id = 'send-form-img';
    sendFormImg.classList.add('dayuse__sendform-img');
    let sendForm = document.createElement('p');
    sendForm.innerHTML = 'Invia e richiedi la disponibilità';
    sendForm.id = 'dayuse__sendform';
    sendForm.classList.add('dayuse__sendform');
    sendForm.type = 'submit';
    document.getElementById('js-dayUse-box').appendChild(sendFormContainer);
    document
      .getElementById('dayuse__sendform-container')
      .appendChild(sendFormImg);
    document.getElementById('dayuse__sendform-container').appendChild(sendForm);
    document
      .getElementById('js-dayUse-box')
      .classList.add('cruscotto__panel-settings--active-center');
    document
      .getElementById('js-info-camera')
      .classList.add('cruscotto__panel-box--active-width');
    document
      .getElementById('calendar-day-use')
      .classList.add('cruscotto__panel-box--active-width');
    document
      .getElementById('day-use-hour')
      .classList.add('cruscotto__panel-box--active-width');
  });

  //////////////////////////////

  document.addEventListener('click', function (event) {
    const isClickInsideElement = document
      .getElementById('js-info-camera')
      .contains(event.target);
    if (
      !isClickInsideElement &&
      document
        .getElementById('dayuse-hamburger')
        .classList.contains('dayuse--active')
    ) {
      document
        .getElementById('dayuse-hamburger')
        .classList.remove('dayuse--active');
      hamburger.style.display = 'none';
      closeHamburger.style.display = 'none';
      hamburgerBox.style.display = 'flex';
      document
        .getElementById('js-info-camera')
        .classList.remove('dayuse__info-day-use');
    }
  });

  document.addEventListener('click', function (event) {
    const isClickInsideElement = document
      .getElementById('day-use-hour')
      .contains(event.target);
    if (
      !isClickInsideElement &&
      document
        .getElementById('dayuse-hour-hamburger')
        .classList.contains('dayuse--active-input')
    ) {
      document
        .getElementById('dayuse-hour-hamburger')
        .classList.remove('dayuse--active-input');
      hamburgerHourBox.classList.remove('dayuse--active-input');
      hamburgerHourBox.style.display = 'none';
      closeHourHamburger.style.display = 'none';
      hamburgerHour.style.display = 'flex';
      document
        .getElementById('day-use-hour')
        .classList.remove('dayuse__hour-day-use');

      let sendFormContainer = document.createElement('button');
      sendFormContainer.id = 'dayuse__sendform-container';
      sendFormContainer.classList.add(
        'dayuse__sendform-container',
        'cruscotto__panel-box'
      );
      let sendFormImg = document.createElement('img');
      sendFormImg.src = 'src/assets/panel-arrow.svg';
      sendFormImg.id = 'send-form-img';
      sendFormImg.classList.add('dayuse__sendform-img');
      let sendForm = document.createElement('p');
      sendForm.innerHTML = 'Invia e richiedi la disponibilità';
      sendForm.id = 'dayuse__sendform';
      sendForm.classList.add('dayuse__sendform');
      sendForm.type = 'submit';
      document.getElementById('js-dayUse-box').appendChild(sendFormContainer);
      document
        .getElementById('dayuse__sendform-container')
        .appendChild(sendFormImg);
      document
        .getElementById('dayuse__sendform-container')
        .appendChild(sendForm);
      document
        .getElementById('js-dayUse-box')
        .classList.add('cruscotto__panel-settings--active-center');
      document
        .getElementById('js-info-camera')
        .classList.add('cruscotto__panel-box--active-width');
      document
        .getElementById('calendar-day-use')
        .classList.add('cruscotto__panel-box--active-width');
      document
        .getElementById('day-use-hour')
        .classList.add('cruscotto__panel-box--active-width');
    }
  });
};
